import { Container, Row, Col } from "react-bootstrap";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import { getYear } from "date-fns";

import './Footer.css';
import { INSTAGRAM_LINK, LINKEDIN_LINK, FACEBOOK_LINK } from "../../constants";

const Footer = () => {
  const thisYear = getYear(new Date());

  return (
    <div className="footer">
      <Container fluid={true}>
        <Row>
          <Col xs={6} className="social_media_links">
            <a href={ INSTAGRAM_LINK } rel="noreferrer" target="_blank">
              <InstagramIcon className="custom_icon" />
            </a>
            <a href={ LINKEDIN_LINK } rel="noreferrer" target="_blank">
              <LinkedInIcon className="custom_icon" />
            </a>
            <a href={ FACEBOOK_LINK } rel="noreferrer" target="_blank">
              <FacebookIcon className="custom_icon" />
            </a>
          </Col>
          <Col xs={6} className="copy_right">
            Knichal Architecture &#169; {thisYear}
            <span style={{fontSize: '12px'}}> &nbsp; Kaftanová, Pokorný</span>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
