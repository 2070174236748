import {Outlet} from 'react-router-dom';

import './App.css';
import {Footer, Header} from "./components";

const App = () => (
  <div>
    <Header/>
    <div className="app_body">
      <Outlet/>
    </div>
    <Footer />
  </div>
)

export default App;