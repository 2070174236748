import './EasterEgg.css';

const EasterEgg = () => (
  <div className="easter_egg">
    <header className="easter_egg_header">
      <img src='images/filip.png' className="easter_egg_logo" alt="logo"/>
      <p>
        Mé jméno je Filip a teď budu Vašim ARCHITEKTEM!
      </p>
    </header>
  </div>
)

export default EasterEgg;
