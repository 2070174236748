import {Col, Container, Row} from "react-bootstrap";

import './About.css';
import { PersonalCard } from '../../components'

const About = () => (
  <div className="about">
    <Container>
      <Row>
        <Col>
          <PersonalCard>Mé jméno je Filip a teď budu Vašim ARCHITEKTEM!</PersonalCard>
          <PersonalCard>
            Ing. arch. Filip Knichal <br /><br />
            Tel.: 777 111 222 <br />
            Adresa: U Vypíchlého Oka 14, Kozoun <br />
          </PersonalCard>
          <PersonalCard>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Vestibulum ullamcorper lorem eget nunc vehicula, sed auctor massa semper.
            Nulla quis mauris quam. Ut egestas suscipit nibh, ac suscipit metus tincidunt quis.
            Pnec purus id ligula vulputate eleifend nec vitae sem.
            Curabitur lectus enim, congue varius venenatis sed, varius eu nunc.
            Sed ullamcorper dui hendrerit lorem varius aliquet.
          </PersonalCard>
          <PersonalCard>
            Green architecture.. Sustainability.. And all that truly amazing stuff
          </PersonalCard>
        </Col>
      </Row>
    </Container>
  </div>
)

export default About;
