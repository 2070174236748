import { useEffect, useState } from "react";

import './SlideShow.css';
import { Fade } from "react-slideshow-image";

const SlideShow = () => {
  const [listOfImages, setImages] = useState([]);

  const importAll = (r) => {
    return r.keys().map(r)
  }

  useEffect(() => {
    setImages(importAll(require.context('../../../public/images/slideShow', false, /\.(png|jpe?g|svg)$/)));
  }, [])

  return (
    <Fade
      cssClass="custom_slide"
      indicators={true}
    >
      {listOfImages.map((i) => (
        <div key={i} className="image_wrapper">
          <img src={i} alt={i} />
        </div>
      ))}
    </Fade>
  )
};

export default SlideShow;
