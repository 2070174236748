import {Col, Container, Row} from "react-bootstrap";

import './Home.css';
import { SlideShow } from '../../components'

const Home = () => (
  <div className="home">
    <Container fluid={true}>
      <Row>
        <Col>
          <SlideShow />
        </Col>
      </Row>
    </Container>
  </div>
)

export default Home;
