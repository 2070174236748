import React from 'react';
import { createBrowserRouter } from "react-router-dom";

import { RouterPaths } from './constants';
import {About, Contact, EasterEgg, Error, Home} from './pages';
import App from './App';

export const router = createBrowserRouter([{
  path: RouterPaths.DEFAULT,
  element: <App />,
  errorElement: <Error />,
  children: [
    {
      path: RouterPaths.DEFAULT,
      element: <Home />,
    }, {
      path: RouterPaths.EASTER_EGG,
      element: <EasterEgg />,
    }, {
      path: RouterPaths.ABOUT,
      element: <About />,
    }, {
      path: RouterPaths.CONTACT,
      element: <Contact />,
    }
  ]
}]);