import './Error.css';
import {RouterPaths} from "../../constants";
import {Nav} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";

const Error = () => (
  <div className="error">
      Error occurred
      <br/>
      <br/>

      Go back to the main page&nbsp;

      <LinkContainer to={RouterPaths.DEFAULT}>
        <Nav.Link className='link'>here</Nav.Link>
      </LinkContainer>

  </div>
)

export default Error;
