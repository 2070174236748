import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';

import { RouterPaths } from '../../constants';
import './Header.css';

const Header = () => (
  <div className="header">
    <Container fluid={true}>
      <Row>
        <Col xs={5} className="header_title">
          <h1>
            <LinkContainer to={RouterPaths.DEFAULT}>
              <Nav.Link className='header-link'>Knichal Architecture</Nav.Link>
            </LinkContainer>
          </h1>
        </Col>
        <Col xs={7} style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Navbar sticky="top" collapseOnSelect expand='md' style={{justifyContent: 'flex-end'}}>
            <Navbar.Toggle aria-controls='basic-navbar-nav'/>
            <Navbar.Collapse>
              <Nav className='me-auto'>
                <LinkContainer to={RouterPaths.ABOUT}>
                  <Nav.Link className='link'>o mně</Nav.Link>
                </LinkContainer>
                <LinkContainer to={RouterPaths.CONTACT}>
                  <Nav.Link className='link'>kontakt</Nav.Link>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Col>
      </Row>
    </Container>
  </div>
)

export default Header;
