import './PersonalCard.css';

const PersonalCard = ({children}) => (
  <div className="personal_card">
    <div className="content">
      {children}
    </div>
  </div>
);

export default PersonalCard;
