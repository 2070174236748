import {Col, Container, Row} from "react-bootstrap";

import './Contact.css';
import { GoogleMap, useLoadScript, MarkerF, InfoWindow  } from '@react-google-maps/api';
import {useMemo} from "react";
import {PersonalCard} from "../../components";

const Contact = () =>{
  const {isLoaded} = useLoadScript({
    googleMapsApiKey: 'AIzaSyAkE1J2C19W0FmSU-9h6L51ccZz7by0kmI',
  });

  const center = useMemo(() => ({ lat: 49.2047794, lng: 16.6054186 }), []);

  const divStyle = {
    background: `white`,
    padding: 15
  }

  if (!isLoaded) return <div>Loading...</div>;

  /**
   * Docs
   * https://tomchentw.github.io/react-google-maps/
   * https://react-google-maps-api-docs.netlify.app/#marker
   * https://www.youtube.com/watch?v=9e-5QHpadi0&ab_channel=GoogleMapsPlatform
   **/
  return (
    <div className="contact">
      <Container>
        <Row>
          <Col>
            <PersonalCard>
              Najdete nás zde
            </PersonalCard>

          </Col>
        </Row>
        <Row>
          <Col>
            <GoogleMap zoom={18} center={center} mapContainerClassName="map-container">
              <MarkerF position={center} title='Karel' />
              <InfoWindow position={center}>
                <div style={divStyle}>
                  <h3>Knichal Architecture</h3>
                  <br />
                  <h6>Děláme architekturu architektoničtější.</h6>
                  <br />
                  U Vypíchlého Oka 14, Kozoun
                </div>
              </InfoWindow>
            </GoogleMap>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Contact;
